<template>
  <v-container class="topic-card-item" @click="onSearch">
    <v-row class="justify-center">
      <div v-html="img"></div>
    </v-row>
    <v-row class="text-center justify-center">
      <router-link :to="link">
      <span class="topic-name topic-name-item px-2">{{ name }}</span>
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
import { CATALOGO } from "../router/routes";
export default {
  name: "HeaderThemeCardItem",
  props: {
    img: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    link () {
      return CATALOGO.name;
    }
  },
  methods: {
    onSearch() {
      this.$store.dispatch("clearSearchParams");

      let payload = this.searchParams;
      payload.topics.push(this.name);

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });
      this.$router.push({ name: CATALOGO.name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.topic-card-item, .topic-name-item {
  color: $text-dark;
  transition: 500ms;
  cursor: pointer;
  .topic-name {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0px;
  }
  span{margin-bottom: 16px;}
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
