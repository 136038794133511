<template>
  <v-menu
    :attach="true"
    :open-on-hover="true"
    :close-on-click="true"
    :close-on-content-click="true"
    :offset-y="true"
    v-model="isOpen"

  >
    <template v-slot:activator="{ on, attrs }">
      <p class="text-lowercase ml-sm-2 dropdown-vmenu-cfpr" v-bind="attrs" v-on="on">
        <span class="link" :class="{ active: isActive }">come fare per</span>
        <v-icon v-if="!isOpen" :color="isHome ? '#ffffff' : '#000000'"
          >keyboard_arrow_down</v-icon
        >
        <v-icon v-else :color="isHome ? '#ffffff' : '#000000'"
          >keyboard_arrow_up</v-icon
        >
      </p>
    </template>
    <v-list>
      <v-list-item v-for="item in items" :key="item.name">
        <router-link :to="item.route">
          <v-list-item-content>
            <v-list-item-title
              class="link"
              v-text="item.name"
            ></v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { RICERCARE, RIUSARE, ADERIRE, YUCCA } from "../router/routes";
export default {
  name: "HeaderDropdown",
  data() {
    return {
      isOpen: false,
      items: [
        {
          name: "ricercare e scaricare i contenuti nel catalogo",
          route: { name: RICERCARE.name },
        },
        {
          name: "riusare i dati",
          route: { name: RIUSARE.name },
        },
        {
          name: "aderire al portale",
          route: { name: ADERIRE.name },
        },
        {
          name: "utilizzare Yucca, la smart data platform",
          route: { name: YUCCA.name },
        }
      ],
    };
  },
  computed: {
    isActive() {
      return this.$route.meta.title.toLowerCase() == "come fare per";
    },
    isHome() {
      return this.$store.getters["isHomePage"];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
a {
  text-decoration: none;
}
.link {
  letter-spacing: 0;
  color: $text-dark;
  font: normal normal 600 16px/28px Open Sans;
  transition: 500ms;
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid $secondary;
  }
  &.active {
    border-bottom: 3px solid $secondary;
  }
}
 .dropdown-vmenu-cfpr {
  margin-left: -11px!important;

 }

 /*.v-menu>.v-menu__content{
   left:850px!important;

 }*/
</style>
