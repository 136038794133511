<template>
<li>
  <router-link :to="route">
    <span class="text-lowercase link ml-sm-2" :class="{ active: isActive }" @click="clearParams">{{ label }}</span>
  </router-link>
</li>
</template>

<script>
export default {
  name: "HeaderLinkItem",
  props: {
    route: { type: Object, required: false },
    label: { type: String, required: false, default: "" },
  },
  computed: {
    isActive() {
      return this.$route.meta.title.toLowerCase() == this.label.toLowerCase();
    },
  },
  methods: {
    clearParams() {
      this.$store.dispatch("clearSearchParams");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
ul{
  list-style-type: none;
}
.link {
  border-bottom: 3px solid transparent;
  letter-spacing: 0;
  color: $text-dark;
  margin-bottom:16px;
  font: normal normal 600 16px/28px Open Sans;
  transition: 500ms;
  &:hover {
    border-bottom: 3px solid $secondary;
  }
  &.active {
    border-bottom: 3px solid $secondary;
  }
}
</style>
