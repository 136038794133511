<template>
  <div>
    <v-list-group class="mobile-dropdown" v-model="isOpen">
      <template v-slot:activator>
        <p class="text-lowercase dropdown-title" :class="{ active: isActive }">
          Come fare per
          <v-icon v-if="!isOpen">keyboard_arrow_down</v-icon>
          <v-icon v-else>keyboard_arrow_up</v-icon>
        </p>
      </template>
      <v-list-item v-for="item in items" :key="item.name" @click="isOpen = !isOpen">
        <router-link :to="item.route">
          <p class="dropdown-link">{{item.name}}</p>
        </router-link>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { RICERCARE, RIUSARE, ADERIRE, YUCCA } from "../router/routes";
export default {
  name: "HeaderDropdown",
  data() {
    return {
      isOpen: false,
      items: [
        {
          name: "ricercare e scaricare i contenuti nel catalogo",
          route: { name: RICERCARE.name },
        },
        {
          name: "riusare i dati",
          route: { name: RIUSARE.name },
        },
        {
          name: "aderire al portale",
          route: { name: ADERIRE.name },
        },
        {
          name: "utilizzare Yucca, la smart data platform",
          route: { name: YUCCA.name },
        }
      ],
    };
  },
  computed: {
    isActive() {
      return this.$route.meta.title.toLowerCase() == "come fare per";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.mobile-dropdown {
  font-weight: 600;
  letter-spacing: 0;
  transition: 500ms;
  .dropdown-title {
    border-bottom: 3px solid transparent;
    color: $text-dark;
    &.active {
      border-bottom: 3px solid $secondary;
    }
    &:hover {
      border-bottom: 3px solid $secondary;
    }
  }
  .dropdown-link {
    font-size: 10px;
    border-bottom: 3px solid transparent;
    color: $text-dark;
    &:hover {
      border-bottom: 3px solid $secondary;
    }
  }
  .v-list-item:hover {
    background-color: transparent;
  }
}
</style>
