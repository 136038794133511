<template>
  <div class="main-layout" :class="{ home: isHome }">
    <!-- loading overlay -->
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- header section -->
    <the-header  v-if="$vuetify.breakpoint.mdAndUp"></the-header>
    <the-mobile-header v-else></the-mobile-header>

    <!-- main app section -->
    <v-main id="main-content">
      <router-view @setLoading="setLoading"></router-view>
    </v-main>

    <!-- footer section -->
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheMobileHeader from "@/components/TheMobileHeader";
import TheFooter from "@/components/TheFooter";
export default {
  name: "MainLayout",
  components: {
    TheHeader,
    TheMobileHeader,
    TheFooter,
  },
  data() {
    return {
      isMobile: false,
      isLoading: false,
    };
  },
  computed: {
    isHome() {
      return this.$store.getters["isHomePage"];
    },
  },
  methods: {
    setLoading(value) {
      this.isLoading = value;
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.main-layout {
  background-color: $bkg-color;
}
</style>