<template>
  <v-container fluid class="mobile-header" :class="{ home: isHome }">
    <div class="first">
      <!-- header first line -->
      <v-row class="first-line pb-3 px-4">
        <div class="container-img img-logo-regione">
        <img
          :src="isHome ? require('@/assets/RegionePiemonte_Logo_bianco.svg') : require('@/assets/RegionePiemonte_Logo.svg')"
          alt="regione piemonte"
        />
        </div>
      </v-row>

      <!-- header menu -->
      <v-row class="px-3">
        <v-btn icon text class="mr-2" @click.stop="menuOpen = !menuOpen">
          <v-icon :color="isHome ? '#ffffff' : '#000000'" large>menu</v-icon>
        </v-btn>
        <router-link :to="homeUrl">
          <p class="menu-title">
            <span class="menu-bold">dati</span>
            <span class="menu-light">piemonte</span>
          </p>
        </router-link>
      </v-row>

      <!-- header links -->
      <v-navigation-drawer v-model="menuOpen" absolute temporary>
        <v-list nav dense>
          <v-list-item class="justify-end">
            <v-icon @click="menuOpen = false">cancel</v-icon>
          </v-list-item>
          <header-mobile-link-item :route="catalogoRoute" label="catalogo"></header-mobile-link-item>
          <header-mobile-link-item :route="entiRoute" label="enti"></header-mobile-link-item>
          <header-mobile-link-item :route="datavizRoute" label="dataviz"></header-mobile-link-item>
          <header-mobile-link-item :route="storieRoute" label="storie"></header-mobile-link-item>
          <header-mobile-dropdown></header-mobile-dropdown>
          <header-mobile-link-item :route="newsRoute" label="news"></header-mobile-link-item>
          <header-mobile-link-item :route="contattiRoute" label="contatti"></header-mobile-link-item>
          <v-list-item>
            <a href="http://www.osservatorioict.piemonte.it/" target="_blank">
              <p class="ict-link mt-6">Osservatorio ICT</p>
            </a>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- home header search -->
      <open-data-search v-if="isHome"></open-data-search>

      <!-- home header search by theme -->
      <header-theme-card v-if="isHome"></header-theme-card>

      <!-- home header search by tag -->
      <header-tag-section v-if="isHome"></header-tag-section>
    </div>
  </v-container>
</template>

<script>
import HeaderThemeCard from "./HeaderThemeCard";
import HeaderTagSection from "./HeaderTagSection";
import OpenDataSearch from "./OpenDataSearch";
import HeaderMobileLinkItem from "./HeaderMobileLinkItem";
import HeaderMobileDropdown from "./HeaderMobileDropdown";
import { HOME, CATALOGO, ENTI, STORIE, DATAVIZ, NEWS, CONTATTI } from "../router/routes";
export default {
  name: "TheMobileHeader",
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    isHome() {
      return this.$store.getters["isHomePage"];
    },
    homeUrl() {
      return { name: HOME.name };
    },
    catalogoRoute() {
      return { name: CATALOGO.name };
    },
    entiRoute() {
      return { name: ENTI.name };
    },
    storieRoute() {
      return { name: STORIE.name };
    },
    datavizRoute() {
      return { name: DATAVIZ.name };
    },
    newsRoute() {
      return { name: NEWS.name };
    },
    contattiRoute() {
      return { name: CONTATTI.name };
    },
  },
  components: {
    OpenDataSearch,
    HeaderThemeCard,
    HeaderTagSection,
    HeaderMobileLinkItem,
    HeaderMobileDropdown,
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.container-img.img-logo-regione{

  max-width:100px!important;
  max-height:30px!important;
  justify-content: flex-start!important;
  align-items: flex-start!important;
  margin:0!important;
  margin-top:4px!important;
}
.mobile-header {
  a {
    text-decoration: none;
    color: $text-dark;
  }
  color: $text-dark;
  .first {
    .first-line {
      border-bottom: 1px solid $text-dark;
    }
    .menu-title {
      color: $text-dark;
      font-size: 22px;
      .menu-bold {
        font-weight: 600;
      }
      .menu-light {
        font-weight: 300;
      }
    }
    .ict-link {
      border-bottom: 3px solid transparent;
      font-weight: 600;
      letter-spacing: 0;
      transition: 500ms;
      text-decoration: underline;
      color: $text-dark;
      &:hover {
        border-bottom: 3px solid $secondary;
      }
    }
  }
  &.home {
    color: $text-white;
    background-image: url("../assets/sfondo_home.png");
    background-size: cover;
    .first {
      .first-line {
        border-bottom: 1px solid $text-white;
      }
      .burger {
        color: $text-white;
      }
      .menu-title {
        color: $text-white;
      }
    }
  }
}
</style>