<template>
  <div
    class="pa-1 pa-sm-2 d-flex align-center"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-btn
      rounded
      :large="isLarge"
      :small="isSmall"
      :x-small="isXSmall"
      class="tag-item"
      @click="onSearch"
      >{{ fullTag }}</v-btn
    >
  </div>
</template>

<script>
import { CATALOGO } from "../router/routes";
export default {
  name: "HeaderTagItem",
  props: {
    count: { type: Number, required: false },
    name: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
  },
  computed: {
    fullTag() {
      return this.label + " (" + this.count + ")";
    },
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    isLarge() {
      return this.count >= 100;
    },
    isSmall() {
      return this.count < 50 && this.count > 25;
    },
    isXSmall() {
      return this.count <= 25;
    },
  },
  methods: {
    onSearch() {
      this.$store.dispatch("clearSearchParams");

      let payload = this.searchParams;
      payload.tags.push(this.name);

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });
      this.$router.push({ name: CATALOGO.name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.tag-item {
  color: $text-tag;
}
.mobile {
  .tag-item {
    font-size: 8px;
  }
}
</style>