<template>
  <div
    class="open-data-search px-2 px-sm-16 pt-6 mb-6 mb-sm-0"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-col class="col-12 col-sm-9 col-lg-6 mx-lg-16">
      <v-row class="sub-1 mb-0 col-12">Il portale degli</v-row>
      <!--v-row class="open-data-title mb-0 col-12">Open Data</!--v-row-->
      <h1 class="open-data-title mb-0 col-12">Open Data</h1>
      <v-row class="sub-2 col-12 mb-10 mb-sm-10">della Regione Piemonte</v-row>

      <v-row class="mt-4" role="search">
        <open-data-search-field></open-data-search-field>
      </v-row>
      <v-row class="align-center">
        <p class="dataset mb-0 px-0 col-12 col-sm-auto">
          <span id="counter" class="dataset-number mr-2">{{
            datasetCount
          }}</span>
          <span class="dataset-title">dataset</span>
        </p>
        <v-spacer></v-spacer>
        <a
          :href="downloadSummaryUrl"
          target="_blank"
          rel="noopener"
          class="download-catalog"
        >
          <v-icon color="#FFFFFF" class="download-all-icon">save_alt</v-icon>
          <span class="pl-1">Scarica il catalogo completo</span>
        </a>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import OpenDataSearchField from "./OpenDataSearchField";
import { getDatasetCount } from "../services/api";
import { animateValue } from "../boot/globals";
export default {
  name: "OpenDataSearch",
  components: { OpenDataSearchField },
  data() {
    return {
      datasetStatistics: {},
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  async created() {
    let datasetCountPromise = getDatasetCount();

    try {
      let { data } = await datasetCountPromise;
      this.datasetStatistics = data;
    } catch (err) {
      console.log(err);
    }

    animateValue("counter", 0, this.datasetCount, 500, 2);
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    datasetList() {
      return this.$store.getters["getDatasetList"];
    },
    datasetCount() {
      return this.datasetStatistics?.totalDatasets ?? 0;
    },
    downloadSummaryUrl() {
      let summaryUrl = "/api/datasets/summary";
      let url = this.baseUrl + summaryUrl;
      return url;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.open-data-search {
  .sub-1 {
    font-weight: 300;
    font-size: 20px;
    padding: 0;
  }
  .open-data-title {
    font-weight: bold;
    letter-spacing: -2.7px;
    font-size: 70px;
    margin-top: -10px;
    padding: 0;
    margin-left:-12px;
  }
  .sub-2 {
    font-weight: 300;
    letter-spacing: 0.5px;
    font-size: 30px;
    padding: 0;
  }
  .dataset {
    font-size: 40px;
    .dataset-number {
      font-weight: 600;
    }
    .dataset-title {
      font-weight: 300;
    }
  }
  .download-catalog {
    .download-all-icon {
      font-size: 18px;
    }
    span {
      text-decoration: underline;
      color: $text-white;
      font-size: 16px;
    }
  }
  &.mobile {
    .open-data-title {
      font-size: 52px;
    }
    .sub-2 {
      font-size: 20px;
    }
  }
}
</style>
