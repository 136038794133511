<template>
  <div class="header" :class="{ home: isHome }">
    <div role="navigation" class="skip-navigation" aria-label="Navigazione veloce">
			<a href="#main-content" class="skip-link">
				Salta al contenuto principale
			</a>
		</div>
    <header>
      <v-container class="first">
        <!-- header first line -->
        <v-row class="first-line pb-3 mx-0">
          <div class="logo-regione"></div>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <a class="first-line-text" href="http://www.osservatorioict.piemonte.it/" target="_blank"
              >Osservatorio ICT</a
            >
          </div>
        </v-row>

        <!-- header links -->
        <header-link-list></header-link-list>

        <!-- home header search -->
        <open-data-search v-if="isHome"></open-data-search>

        <!-- home header search by theme -->
        <header-theme-card v-if="isHome"></header-theme-card>

        <!-- home header search by tag -->
        <header-tag-section v-if="isHome"></header-tag-section>
      </v-container>
    </header>
  </div>

</template>

<script>
import HeaderLinkList from "./HeaderLinkList";
import HeaderThemeCard from "./HeaderThemeCard";
import HeaderTagSection from "./HeaderTagSection";
import OpenDataSearch from "./OpenDataSearch";
export default {
  name: "TheHeader",
  computed: {
    isHome() {
      return this.$store.getters["isHomePage"];
    },
  },
  components: {
    HeaderLinkList,
    OpenDataSearch,
    HeaderThemeCard,
    HeaderTagSection,
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.header {
  color: $text-dark;
  .container.first{
    padding-top:12px;
  }
  a {
    text-decoration: none;
  }
  .logo-regione {
    background-image: url("../assets/RegionePiemonte_Logo.svg");
    background-size: contain;
    width: 50%;
    height: 50px;
  }
  .first-line {
    border-bottom: 1px solid $text-dark;
        padding-top: 12px;
    .first-line-text {
      font-family: "Titillium Web", sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: $text-dark;
    }
  }
  &.home {
    color: $text-white;
    background-image: url("../assets/header_img.svg"),
      url("../assets/sfondo_home.png");
    background-size: contain, 100% 100%;
    .logo-regione {
      background-image: url("../assets/RegionePiemonte_Logo_bianco.svg");
    }
    .first-line {
      border-bottom: 1px solid $text-white;
    }
    .link {
      color: $text-white;
    }

    .v-menu--attached .link{

      color:#000;
    }
    .first-line-text {
      color: $text-white;
    }
  }
}
</style>