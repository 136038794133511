<template>
  <v-container
    fluid
    class="tags-section py-xl-16 mb-16"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-card-title class="py-8 tag-title justify-center"
      ><h2>Naviga per tag</h2></v-card-title
    >
    <v-card-text class="py-4 pb-16 tag-body row justify-center">
      <header-tag-item
        v-for="tag in orderedTags"
        :key="tag.name"
        :name="tag.name"
        :label="tag.displayValue"
        :count="tag.frequency"
      ></header-tag-item>
    </v-card-text>
  </v-container>
</template>

<script>
import HeaderTagItem from "./HeaderTagItem";
export default {
  name: "HeaderTagSection",
  components: {
    HeaderTagItem,
  },
  computed: {
    tagList() {
      return this.$store.getters["getHomeTagsList"];
    },
    orderedTags() {
      let orderedTags = this.sortByName(this.tagList);
      return orderedTags;
    },
  },
  methods: {
    sortByName(items) {
      let result = items.sort((a, b) => {
        a = a.displayValue.toUpperCase();
        b = b.displayValue.toUpperCase();

        return a > b ? 1 : -1;
      });

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.skeleton-loader {
  width: 100%;
}
.tag-title h2 {
  font-weight: bold;
  font-size: 34px;
}
.mobile {
  .tag-title h2{
    font-size: 24px;
  }
}
</style>
