<template>
  <v-footer
    class="footer mt-4"
    id="footer-conth"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-container>
      <!-- logo dati piemonte -->
      <v-row class="main-logo mx-0">
        <v-col class="col-sm-auto my-3 px-0">
          <img
            :src="require('@/assets/logo_bianco.svg')"
            alt="datipiemonte"
            class="logo-opendata"
            title="dati piemonte"
          />
          <img
            :src="require('@/assets/poweredBy.png')"
            alt="powered-by yucca"

            class="powered-by"
          />
        </v-col>
      </v-row>
      <!-- descrizione dati piemonte -->
      <v-row class="description mx-0">
        <v-col class="col-12 col-sm-4 my-3 px-0">
          <p class="font-weight-bold">DATI PIEMONTE</p>
          <p class="font-weight-regular">
            Portale Open Data della Regione Piemonte
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-sm-4 my-3 hidden-xs-only">
          <v-row class="justify-end">
            <p class="font-weight-bold pr-4">SEGUICI SU</p>
          </v-row>
          <v-row class="icons justify-end pr-4">
            <v-btn
              small
              icon
              href="https://twitter.com/OpenDataPiemont"
              target="_blank"
            >
              <img
                :src="require('@/assets/twitter.svg')"
                alt="twitter-logo"
                class="img-max-w-35"
              />
              <span class="sr-only">twitter</span>
            </v-btn>
            <v-btn
              small
              icon
              class="ml-2 justify-end"
              href="https://www.facebook.com/OpenData.Piemonte/"
              target="_blank"
            >
              <img
                :src="require('@/assets/facebook.svg')"
                alt="facebook-logo"
                class="img-max-w-35"
              />
              <span class="sr-only">facebook</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="logos">
        <v-col class="col-12 col-sm-4 my-3 pl-6 hidden-sm-and-up">
          <v-row>
            <p class="font-weight-bold my-1">SEGUICI SU</p>
          </v-row>
          <v-row class="icons">
            <v-btn
              small
              icon
              href="https://twitter.com/OpenDataPiemont"
              target="_blank"
            >
              <img
                :src="require('@/assets/twitter.svg')"
                alt="twitter"
                class="img-max-w-35"
                title="twitter"
              />
            </v-btn>
            <v-btn
              small
              icon
              class="ml-2 justify-end"
              href="https://www.facebook.com/OpenData.Piemonte/"
              target="_blank"
            >
              <img
                :src="require('@/assets/facebook.svg')"
                alt="facebook"
                class="img-max-w-35"
                title="facebook"
              />
            </v-btn>
          </v-row>
        </v-col>
        <!-- loghi regione -->
        <v-col class="col-sm-2">
          <a href="https://www.regione.piemonte.it/web/" target="_blank">
            <img
              :src="require('@/assets/logo_regione_colour.svg')"
              alt="regione piemonte logo"
              class="img-w50"
            />
            <span class="sr-only">regione piemonte</span>
          </a>
        </v-col>
        <v-col class="col-12 col-sm-2">
          <a href="https://www.csipiemonte.it/web/it" target="_blank">
            <img
              :src="require('@/assets/logo_csi.svg')"
              alt="csi piemonte logo"
              class="img-w50"
            />
            <span class="sr-only">csi piemonte</span>
          </a>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-12 col-sm-3">
          <a
            href="https://www.europarl.europa.eu/factsheets/it/sheet/95/il-fondo-europeo-di-sviluppo-regionale-fesr-"
            target="_blank"
          >
            <img
              :src="require('@/assets/logo_fesr.png')"
              class="logo-fesr img-w50"
              alt="fesr"
            />
            <span class="sr-only">iniziativa co-finanziata con fesr</span>
          </a>
        </v-col>
        <v-col class="col-12 col-sm-3 text-right">
          <a
            href="https://ec.europa.eu/regional_policy/it/funding/erdf/"
            target="_blank"
          >
            <img
              :src="require('@/assets/logo_fondo_europeo.png')"
              alt="fondo europeo"
              class="img-w50"
            />
            <span class="sr-only">il fondo-europeo sviluppo regionale </span>
          </a>
        </v-col>
      </v-row>
      <!-- licenza -->
      <v-row class="disclaimer my-6 pl-2 pl-sm-0">
        <v-col class="col-12 col-md-9 d-flex d-flex flex-column align-start">
          <p class="font-weight-regular my-2 my-sm-0">
            Tranne per i materiali specificatamente ed espressamente indicati
            come diversamente tutelati,
            <br v-if="$vuetify.breakpoint.smAndUp" />
            i contenuti del sito sono soggetti alla licenza Creative Commons
            Attribution CC BY 4.0
          </p>
          <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
            ><img
              alt="Licenza Creative Commons"
              class="img-border"
              src="https://i.creativecommons.org/l/by/4.0/88x31.png"
          /></a>
        </v-col>
      </v-row>
      <!-- link legali -->
      <v-row class="legal-links d-flex px-4">
        <p class="legal-link pl-3 pl-sm-0 pr-3"><a href="https://form.agid.gov.it/view/f43c3e13-91b2-43bd-b6b1-902ab290464e"  target="_blank">Dichiarazione di accessibilità</a></p>
        <p class="legal-link pl-3 pl-sm-0 pr-3"><a href="https://www.regione.piemonte.it/web/note-legali-privacy" target="_blank">Note legali e privacy</a></p>
        <p class="legal-link pl-3 pl-sm-0 pr-3"><router-link to="/cookie-policy">Cookie policy</router-link></p>
        <p class="legal-link pl-3 pl-sm-0 pr-3"><a href="#" class="ccb__edit">Impostazione cookie</a></p>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    logoPosition() {
      return window.innerWidth < 700 ? "left center" : "right center";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.img-border {
  border-width: 0;
}
.footer {
  color: $text-white;
  .main-logo {
    border-bottom: 1px solid $text-white;
  }
 .regione-piemonte,
  .logo-opendata {
    margin-left: -10px;
  }
  .logo-opendata{
    height:50px;
    width:300px;
    display: block;
  }
  .powered-by {
    margin-left: 129px;
    margin-top: -20px;
    height:50px;
    //width:220px;
  }
  .logo-fesr,
  .img-w50{
    height:46px;
  }
  .description {
    border-bottom: 1px solid $text-white;
    p {
      margin-bottom: 0;
    }
  }
  .disclaimer {
    p {
      margin-bottom: 0;
    }
  }
  .legal-links {
    .legal-link a {
      color: $text-white;
      text-decoration: none;
    }
  }
  &.mobile {
    .legal-links {
      flex-direction: column;
    }
  }
}

img.img-max-w-35{
  max-width:35px;
}

footer.footer#footer-conth{
  height:auto;
  background-color:#304796;
}
</style>

