<template>
  <v-row>
    <v-col class="col-sm-2 col-md-3 mt-3">
      <div class="container-img">
      <router-link :to="homeUrl">
        <img :src="isHome ? require('@/assets/logo_header_bianco.svg') : require('@/assets/logo_header_colorato.svg')" alt="dati piemonte" class="img-generic-100"/>
      </router-link>
      </div>
    </v-col>
    <v-col class="col-sm-10 col-md-9 d-flex flex-column">
      <v-row class="icons justify-end pr-2 col-social">
        <v-btn small icon href="https://twitter.com/OpenDataPiemont" target="_blank">
          <img
            :src="isHome ? require('@/assets/twitter.svg') : require('@/assets/twitter_blue.svg')"
            alt="twitter-logo"
            class="img-max-w-20"
            title="twitter"
           />
          <span class="sr-only">twitter</span>
        </v-btn>
        <v-btn small icon class="ml-2" href="https://www.facebook.com/OpenData.Piemonte/" target="_blank">
          <img
            :src="isHome ? require('@/assets/facebook.svg') : require('@/assets/facebook_blue.svg')"
            alt="facebook-logo"
            title="facebook"
            class="img-max-w-20"
          />
          <span class="sr-only">facebook</span>
        </v-btn>
      </v-row>
      <v-row class="justify-end">
        <nav aria-label="Menu principale">
          <ul class="nav-ul">
            <header-link-item :route="catalogoRoute" label="catalogo" class="px-sm-2 px-md-3"></header-link-item>
            <header-link-item :route="entiRoute" label="enti" class="px-sm-2 px-md-3"></header-link-item>
            <header-link-item :route="datavizRoute" label="dataviz" class="px-sm-2 px-md-3"></header-link-item>
            <header-link-item :route="storieRoute" label="storie" class="px-sm-2 px-md-3"></header-link-item>
            <header-dropdown class="px-sm-2 px-md-3"></header-dropdown>
            <header-link-item :route="newsRoute" label="news" class="px-sm-2 px-md-3"></header-link-item>
            <header-link-item :route="contattiRoute" label="contatti" class="px-sm-2 px-md-3"></header-link-item>
          </ul>
        </nav>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import HeaderLinkItem from "./HeaderLinkItem";
import HeaderDropdown from "./HeaderDropdown";
import { HOME, CATALOGO, ENTI, STORIE, DATAVIZ, NEWS, CONTATTI } from "../router/routes";
export default {
  name: "HeaderLinkList",
  components: {
    HeaderLinkItem,
    HeaderDropdown
  },
  computed: {
    isHome() {
      return this.$store.getters["isHomePage"];
    },
    homeUrl() {
      return { name: HOME.name }
    },
    catalogoRoute() {
      return { name: CATALOGO.name };
    },
    entiRoute() {
      return { name: ENTI.name };
    },
    storieRoute() {
      return { name: STORIE.name };
    },
    datavizRoute() {
      return { name: DATAVIZ.name };
    },
    newsRoute() {
      return { name: NEWS.name };
    },
    contattiRoute() {
      return { name: CONTATTI.name };
    },
  },
};
</script>

<style scoped lang="scss">
.col-social{
  padding:12px;
}
ul.nav-ul{
      list-style-type: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
}
img.img-max-w-20{
  max-width:20px;
}
.container-img{
  height:75px!important;
}
</style>
