<template>
  <v-list-item>
    <router-link :to="route">
      <p class="text-lowercase link" :class="{ active: isActive }">{{ label }}</p>
    </router-link>
  </v-list-item>
</template>

<script>
export default {
  name: "HeaderMobileLinkItem",
  props: {
    route: { type: Object, required: false },
    label: { type: String, required: false, default: "" },
  },
  computed: {
    isActive() {
      return this.$route.meta.title.toLowerCase() == this.label.toLowerCase();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.link {
  border-bottom: 3px solid transparent;
  font-weight: 600;
  letter-spacing: 0;
  color: $text-dark;
  transition: 500ms;
  &:hover {
    border-bottom: 3px solid $secondary;
  }
  &.active {
    border-bottom: 3px solid $secondary;
  }
}
</style>
