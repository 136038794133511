<template>
  <v-container
    fluid
    class="search-by-topic py-8 mb-8 px-lg-16 py-xl-16"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-card flat class="py-xl-16">
      <v-card-title class="pb-10 topic-title justify-center">
        <h2>Naviga per temi</h2>
        </v-card-title>
      <v-row class="py-4 topic-body justify-center">
        <v-col
          v-for="topic in orderedTopics"
          :key="topic.id"
          class="col-12 col-sm-3"
          :class="{ seven: $vuetify.breakpoint.mdAndUp }"
        >
          <header-theme-card-item
            :name="topic.themeit"
            :img="topic.svg"
          ></header-theme-card-item>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import HeaderThemeCardItem from "./HeaderThemeCardItem";
export default {
  name: "HeaderThemeCard",
  components: {
    HeaderThemeCardItem,
  },
  computed: {
    topicsList() {
      return this.$store.getters["getHomeTopicsList"];
    },
    orderedTopics() {
      let orderedTopics = this.sortByName(this.topicsList);
      return orderedTopics;
    },
  },
  methods: {
    sortByName(items) {
      let result = items.sort((a, b) => {
        a = a.themeit.toUpperCase();
        b = b.themeit.toUpperCase();

        return a > b ? 1 : -1;
      });

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.search-by-topic {
  .topic-title h2 {
    font-weight: bold;
    font-size: 34px;
  }
  .seven {
    width: 14%;
    max-width: 14%;
  }
  &.mobile {
    .topic-title h2{
      font-size: 24px;
    }
  }
}
</style>
